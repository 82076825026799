<template>
  <div>
    <h5 class="info-text">{{ $t('transfer_summary') }}</h5>

    <div class="row justify-content-center">

      <div class="col-lg-10">

        <div class="card">
          <div class="card-body table-responsive table-full-width">
            <div class="row" v-if="model.user !== null">
              <!--
              <div class="col-5 text-center lead">
                {{ user.user_metadata.first_name }} {{ user.user_metadata.last_name }}<br/>({{ user.email }})
              </div>
              -->
              <div class="col-5 text-center lead">
                {{ $t('me') }}<br/>({{ user.email }})
              </div>
              <div class="col-2 text-center text-success">
                <i class="fa fa-long-arrow-right fa-huge"></i>
              </div>
              <div class="col-5 text-center" v-if="model.broker_deposit">
                <div class="broker-icon">
                  <img :src="model.broker_data.icon">
                </div>
                <h6>{{ model.broker_data.name }}</h6>
              </div>
              <div class="col-5 text-center lead" v-else>
                <strong>{{ model.user.first_name }} {{ model.user.last_name }}</strong><br/>({{ model.user.userid }})
              </div>
            </div>

            <div class="row" v-if="model.user !== null">
              <div class="col-12 text-center transfer-money">
                {{ model.transfer_val }} (HKO)
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>
<script>
  const user_data = JSON.parse(localStorage.getItem('user-info'));

  export default {
    data() {
      return {
        model: { summaryRead: true, user: null, transfer_val: 0, broker_deposit: false, broker_data: null }, user: null
      }
    },
    methods: {
      reload(user, transfer_val, broker_deposit, broker_data) {
        this.model.user = user;
        this.model.transfer_val = transfer_val;
        this.model.broker_deposit = broker_deposit;
        this.model.broker_data = broker_data;
        //console.log(this.model);
      },
      validate() {
        return this.$validator.validateAll().then(res => {
          this.$emit('on-validated', res, this.model)
          return res
        });
      }
    },
    mounted () {
      this.user = user_data;
    }
  }
</script>
<style lang="scss" scoped>
  .fa-huge {
    font-size: 4.5em;
  }
  .transfer-money {
    font-size: 3em;
  }

  .broker-icon {
    text-align: center;
    vertical-align: middle;
    height: 116px;
    width: 116px;
    border-radius: 50%;
    color: #66615B;
    margin: 0 auto 20px;
    border: 1px solid #E3E3E3;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    overflow: hidden;
  }
</style>
