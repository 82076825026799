<template>
  <module
      ref="module"
      id="transfer"
      titleIcon="fa fa-exchange"
      :title="$t('transfer_title')"
      :use-default-list="false"
    >
    <div slot="global">
      <div class="row d-flex justify-content-center">
      <div class="col-md-10 mr-auto ml-auto">
          <div :class="alert_class" class="ml-2 mb-2 text-center" style="display: block;font-weight: bold;" v-if="alert_me">
            {{ alert_msg }}
          </div>

          <div v-if="inprogress" class="text-center">
            <h2>{{ inprogresstxt }}</h2>
            <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
          </div>

          <wizard :prevButtonText="prevBtnTxt" :nextButtonText="nextBtnTxt" :finishButtonText="finBtnTxt" v-else>
            <template slot="header">
              <h3 class="card-title">{{ $t('transfer_title') }}</h3>
              <h3 class="description">{{ $t('transfer_why') }}</h3>

            </template>

            <wizard-tab :before-change="() => validateStep('step1')">
              <template slot="label" >
                <i class="fa fa-user"></i>
                {{ $t('transfer_user') }}
              </template>
              <choose-user-step ref="step1" @on-validated="onStepValidated"></choose-user-step>
            </wizard-tab>

            <wizard-tab :before-change="() => validateStep('step2')" v-if="isadmin == false && walletapp">
              <template slot="label" >
                <i class="fa fa-exchange"></i>
                {{ $t('transfer_summary') }}
              </template>
              <transfer-summary ref="step2" @on-validated="onStepValidated"></transfer-summary>
            </wizard-tab>

          </wizard>
      </div>
    </div>
    </div>
  </module>
</template>
<script>
  import ChooseUserStep from './TransferWiz/GetUserStep.vue'
  import TransferSummary from './TransferWiz/Summary.vue'
  import Constants from 'src/assets/constants'

  import swal from 'sweetalert2'
  import {Wizard, WizardTab} from 'src/components/UIComponents'

  const user_data = JSON.parse(localStorage.getItem('user-info'));
  export default {
    data() {
      return {
        wizardModel: {},
        prevBtnTxt: this.$t('previous'),
        nextBtnTxt: this.$t('next'),
        finBtnTxt: this.$t('starttransfer'),
        inprogresstxt: this.$t('transfer_inprogress'),
        inprogress: false,
        alert_me: false,
        alert_msg: null,
        alert_class: 'text-danger',
        isadmin: false,
        walletapp: Constants.methods.walletme(),
        recipient: null,
        transfer_value: 0,
        ueml: null,
        isbroker: false
      }
    },
    components: {
      ChooseUserStep, Wizard, WizardTab, TransferSummary
    },
    methods: {
      validateStep(ref) {
        return this.$refs[ref].validate()
      },
      onStepValidated(validated, model) {
        this.wizardModel = {...this.wizardModel, ...model};
        let proceed = true;
        const successop = resp => {
          this.inprogress = false;
          if (!resp.success) {
            this.$toast.warning(this.$t(resp.msg))
            return;
          }
          const successMsg = this.wizardModel.broker_data && this.wizardModel.broker_data.donation ?
            this.$t('successtransfer_donation') :
            this.$t('successtransfer', [this.wizardModel.user.userid, this.wizardModel.transfer_val])
          swal('Good job!', successMsg, 'success').then(this.showInoutPage)
        };

        const failop = error => {
          this.inprogress = false;
          this.alert_me = true;
          let parts = error.data.msg.split(', ')
          this.alert_msg = parts[1] || this.$t('failtransfer');

        };

        this.alert_me = false;
        if (this.wizardModel.user !== null && this.wizardModel.user !== undefined && this.wizardModel.transfer_val >= 0) {
          if (this.wizardModel.summaryRead === undefined) {
            this.$refs['step2'].reload(this.wizardModel.user, this.wizardModel.transfer_val, this.wizardModel.broker_deposit, this.wizardModel.broker_data);
          }
          else {
            this.inprogress = true;
            //console.log(this.wizardModel);
            let json_data = {
              "toUser": this.wizardModel.user.userid,
	             "value": this.wizardModel.transfer_val
            };
            if (this.wizardModel.transfer_notes !== null && this.wizardModel.transfer_notes !== undefined && this.wizardModel.transfer_notes !== '' && this.wizardModel.transfer_notes.length > 0) {
              json_data["comment"] = this.wizardModel.transfer_notes;
            }
            this.$transferBalance(user_data.email, json_data)
              .then(successop, failop);
          }
        }
      },
      showInoutPage(){
          if (this.adminusr) {
            location.href = "/billing/inout/"+ this.ueml;
          } else {
            location.href = "/billing/inout";
          }
      },
      wizardComplete() {
        swal('Good job!', 'You clicked the finish button!', 'success')
      }
    },
    mounted() {
      this.ueml = user_data.email;
      if (user_data.roles.indexOf('admin') >= 0) {
        this.isadmin = true;
      }
      if (user_data['roles'].indexOf('broker') >= 0) {
        this.isbroker = true;
      }
    },
    created(){
      this.$setTranslatingInProgress()
    },
    beforeDestroy(){
      this.$root.$off("showTranslatingProgress")
    },
  }
</script>
<style lang="scss">
</style>
